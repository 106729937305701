import React, { useEffect, useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import {
  Button, Checkbox, CircularProgress, styled, TextField,
} from "@material-ui/core";
import MainContext from "./context/main/mainContext";

function getModalStyle() {
  return {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    display: "block",
    "overflow-y": "auto",
    "max-height": "80%",
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function UserModal(props) {
  const mainContext = useContext(MainContext);
  const {
    user,
    getMemberById,
    setMemberAdmin,
    setEmailVerified,
    member,
    setPostFlowEmail,
    changeTab,
    getLoginToken,
    isProd,
  } = mainContext;

  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    // Lookup User by ID
    getMemberById(props.userId, (err) => {
      if (!props.userId) return;
      if (err) {
        alert(err);
      }
      setIsLoading(false);
    });
  }, [props.userId]);

  const makeAdmin = (userId, teamId, isAdmin) => {
    setIsLoading(true);
    setMemberAdmin(userId, teamId, isAdmin, (err) => {
      if (err) {
        alert(err);
      }
      setIsLoading(false);
    });
  };

  const changeEmailVerified = (userId, emailVerified) => {
    if (isLoading) return;

    setIsLoading(true);
    setEmailVerified(userId, emailVerified, (err) => {
      if (err) {
        alert(err);
      }
      setIsLoading(false);
    });
  };

  const startTeamsTrial = (email) => {
    setPostFlowEmail(email);
    changeTab(3);
  };

  const adminLogin = (uid, isStaging) => {
    setIsLoading(true);
    getLoginToken(uid, (err, token) => {
      if (err) {
        alert(err);
      }

      let baseUrl = "https://dash.popl.co/";
      if (!isProd) {
        baseUrl = "https://dev-teams.popl.co/";
      }
      if (isStaging) {
        baseUrl = "https://staging-teams.popl.co/";
      }
      // Open in new tab
      window.open(`${baseUrl}sign-in?addon=${token}`, "_blank");
      setIsLoading(false);
    });
  };

  const body = (
    <div>
      <h2 id="simple-modal-title">Member</h2>
      { user.can_access_admin
        && <div className="buttonContainer" onClick={(e) => adminLogin(member?.member?.uid)}>
          Admin Login
        </div>
      }
      { user.can_access_admin && isProd
        && <div className="buttonContainer" onClick={(e) => adminLogin(member?.member?.uid, true)}>
          Staging Login
        </div>
      }
      <p id="simple-modal-description">
        Email: {member?.member?.email}
      </p>
      <p>
        ID: {member?.member?.id}
      </p>
      <p>
        Firebase ID: {member?.member?.uid}
      </p>
      <div className="checkboxDiv">
        <Checkbox
          color="black"
          checked={member?.member?.emailVerified}
          size="small"
          onChange={(e) => changeEmailVerified(member?.member?.id, !member?.member?.emailVerified)}
          disabled={!user.can_access_admin}
        />
        <p>Email Verfified</p>
      </div>
      {member?.member?.memberTeams?.length > 0 && member?.member?.activeTeamsMember !== 0 ? (<h3>Team</h3>)
        : (
          <div className="buttonContainer" onClick={(e) => startTeamsTrial(member?.member?.email)}>
            Create New Team
          </div>
        )
      }
      { member?.member?.activeTeamsMember !== 0
        && member?.member?.memberTeams.map((memberTeam, index) => (
          <div>
            <div className="buttonContainer" onClick={(e) => props.openTeamFn(memberTeam?.team?.id)}>
                Open Team
            </div>
            <p>
                Team ID: {memberTeam?.team?.id}
            </p>
            <p>
                Is Admin: {memberTeam?.isAdmin ? "Yes" : "No"}
            </p>
            { user.can_access_admin ? memberTeam?.isAdmin ? (
              <div className="buttonContainerAlt" onClick={isLoading ? null : (e) => makeAdmin(member?.member?.id, memberTeam?.team?.id, false)}>
                {isLoading ? (<CircularProgress color="secondary" size={15} />) : "Remove Admin"}
              </div>
            ) : (
              <div className="buttonContainerAlt" onClick={isLoading ? null : (e) => makeAdmin(member?.member?.id, memberTeam?.team?.id, true)}>
                {isLoading ? (<CircularProgress color="secondary" size={15} />) : "Make Admin"}
              </div>
            ) : null}
          </div>
        ))
      }
    </div>
  );

  return (
    <Modal
      open={props.open}
      onClose={props.closeModal}
    >
      <div style={modalStyle} className={classes.paper}>
        {isLoading ? <CircularProgress /> : body }
      </div>
    </Modal>
  );
}
