import {
  Button, Checkbox, CircularProgress, styled, TextField,
} from "@material-ui/core";
import React, { useContext, useState } from "react";
import MainContext from "./context/main/mainContext";

const CustomButton = styled(Button)(() => ({
  color: "#fff",
  backgroundColor: "#000",
  "&:hover": {
    backgroundColor: "#2D2D2D",
  },
  "&:disabled": {
    backgroundColor: "#9a9a9a60",
  },
  width: 150,
  height: 35,
  textTransform: "none",
  fontSize: 13,
  fontWeight: 500,
  borderRadius: 8,
  fontFamily: "Inter, sans-serif",
  transition: "0.2s !important",
}));

const PostDemoFlow = () => {
  const mainContext = useContext(MainContext);
  const { createTeam, prePostDemoFlowStoredEmail } = mainContext;

  const [teamInfoPayload, setTeamInfo] = useState({
    teamOwnerEmail: prePostDemoFlowStoredEmail || "",
    teamOwnerName: "",
    memberEmails: "",
  });

  const [loading, setLoading] = useState(false);
  const [addAdminToTeam, setAdminAsMember] = useState(true);

  const onCreateTeam = () => {
    setLoading(true);
    const newEmailArr = teamInfoPayload.memberEmails.split(/,\s*|\s+/);
    createTeam(
      { ...teamInfoPayload, memberEmails: addAdminToTeam ? [...newEmailArr.filter((x) => x), teamInfoPayload.teamOwnerEmail] : newEmailArr.filter((x) => x) },
      () => setLoading(false),
    );
  };

  return (
    <div className="projectContainer">
      <div className="appHeaderBio">
        <h1>Create New Team</h1>
        <p>Use this tool to create a new Popl Team.</p>
      </div>
      <div className="textInputContainer">
        <TextField
          variant="outlined"
          onChange={(e) => {
            if (e.target.value.includes(" ")) return;
            if (e.target.value.length > 300) return alert("Too many characters");
            setTeamInfo({ ...teamInfoPayload, teamOwnerEmail: e.target.value });
          }}
          label={"Team owner's email (should match SF opportunity)"}
          placeholder={"team-owner@popl.co"}
          value={teamInfoPayload.teamOwnerEmail}
          InputProps={{
            style: {
              fontSize: 12, height: 50, fontWeight: 500, borderRadius: 8, color: "#000", display: "block",
            },
          }}
          InputLabelProps={{
            style: {
              fontSize: 12, height: 50, fontWeight: 500, borderRadius: 8, color: "#828282",
            },
          }}
          style={{ width: 400 }}
          rows={1}
        />
      </div>
      <div className="textInputContainer">
        <TextField
          variant="outlined"
          onChange={(e) => {
            if (e.target.value.length > 300) return alert("Too many characters");
            setTeamInfo({ ...teamInfoPayload, teamOwnerName: e.target.value });
          }}
          label={"Team owner's name"}
          placeholder={"Elon Musk"}
          value={teamInfoPayload.teamOwnerName}
          InputProps={{
            style: {
              fontSize: 12, height: 50, fontWeight: 500, borderRadius: 8, color: "#000", display: "block",
            },
          }}
          InputLabelProps={{
            style: {
              fontSize: 12, height: 50, fontWeight: 500, borderRadius: 8, color: "#828282",
            },
          }}
          style={{ width: 400 }}
          rows={1}
        />
      </div>
      <div className="textInputContainer">
        <TextField
          variant="outlined"
          onChange={(e) => {
            if (e.target.value.length > 1000) return alert("Too many characters");
            setTeamInfo({ ...teamInfoPayload, memberEmails: e.target.value });
          }}
          label={"Additional member emails - optional (seperate by comma)"}
          placeholder={"member1@popl.co, member2@popl.co, member3@popl.co"}
          value={teamInfoPayload.memberEmails}
          InputProps={{
            style: {
              fontSize: 12, height: 100, fontWeight: 500, borderRadius: 8, color: "#000", display: "block",
            },
          }}
          InputLabelProps={{
            style: {
              fontSize: 12, height: 100, fontWeight: 500, borderRadius: 8, color: "#828282",
            },
          }}
          style={{ width: 500 }}
          rows={4}
          multiline
        />
      </div>
      <div className="checkboxDiv">
        <Checkbox
          color="black"
          checked={addAdminToTeam}
          size="small"
          onChange={() => setAdminAsMember(!addAdminToTeam)}
        />
        <p>Add team owner as admin within Popl Teams
          <br />
          <span>Uncheck if the team owner's email is only used for billing purposes</span>
        </p>
      </div>
      <div className="createButtonDiv">
        <br />
        <CustomButton
          disabled={
            teamInfoPayload.teamOwnerEmail?.length <= 0
                        || teamInfoPayload.memberEmails === "" && !addAdminToTeam
          }
          onClick={onCreateTeam}
        >
          {
            loading ? (
              <CircularProgress color="secondary" size={15} />
            ) : "Create new team"
          }
        </CustomButton>
      </div>
    </div>
  );
};

export default PostDemoFlow;
