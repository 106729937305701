import {
  LOGIN,
  STRIPE_SUBSCRIPTION_PREVIEW,
  STRIPE_INVOICE,
  SEARCH,
  CHANGE_TAB,
  POST_CREATE_TEAM,
  MARK_INVOICE_SENT,
  DEMO_FLOW_DONE,
  GET_MEMBER,
  GET_TEAM,
  STRIPE_PRICE_CONFIG,
  POST_UPGRADE_TEAM,
  POST_SET_EMAIL_STEP,
  STRIPE_SUBSCRIPTION_EXTENDED,
  STRIPE_CUSTOMER,
  STRIPE_CUTOMER_PAYMENT_METHODS,
  SET_ENV,
  GET_HR_CONNECTIONS,
  GET_AD_GROUP_USERS,
  GET_AD_GROUPS,
  UPDATE_HR_CONNECTION_SYNC_CONF,
  GET_MS_AD_MAPPING_CONF,
} from "./types";

const action_switch = (state, action) => {
  switch (action.type) {
  case LOGIN: {
    return {
      ...state,
      user: action.payload,
    };
  }
  case CHANGE_TAB: {
    return {
      ...state,
      currentTab: action.payload,
    };
  }
  case POST_SET_EMAIL_STEP: {
    return {
      ...state,
      prePostDemoFlowStoredEmail: action.email,
    };
  }
  case POST_CREATE_TEAM: {
    return {
      ...state,
      postDemoFlowStoredEmail: action.email,
      postDemoFlowStoredTeamId: action.id,
      invoiceSent: false,
      postDemoFlowFinished: false,
      stripe_customer: action.stripeCustomer,
      postDemoFlowStoredpriceId: action.priceId,
      postDemoFlowUpgrade: false,
      postDemoFlowStoredLoginToken: action.loginToken,
      postDemoFlowStoredTeamSize: action.teamSize,
    };
  }
  case POST_UPGRADE_TEAM: {
    return {
      ...state,
      postDemoFlowStoredEmail: action.email,
      postDemoFlowStoredTeamId: action.id,
      stripe_customer: action.stripeCustomer,
      postDemoFlowUpgrade: true,
      postDemoFlowStoredTeamSize: action.teamSize,
    };
  }
  case MARK_INVOICE_SENT: {
    return {
      ...state,
      invoiceSent: true,
    };
  }
  case DEMO_FLOW_DONE: {
    return {
      ...state,
      postDemoFlowFinished: true,
    };
  }
  case STRIPE_CUSTOMER: {
    return {
      ...state,
      stripe_customer: action.payload,
    };
  }
  case STRIPE_CUTOMER_PAYMENT_METHODS: {
    return {
      ...state,
      stripe_customer_payment_methods: action.payload,
    };
  }
  case STRIPE_SUBSCRIPTION_PREVIEW: {
    return {
      ...state,
      stripe_subscription_preview: action.payload,
    };
  }
  case STRIPE_SUBSCRIPTION_EXTENDED: {
    return {
      ...state,
      stripe_subscription_extended: action.payload,
      invoiceSent: false,
    };
  }
  case STRIPE_INVOICE: {
    return {
      ...state,
      stripe_invoice: action.payload,
    };
  }
  case SEARCH: {
    return {
      ...state,
      search_results: action.payload,
    };
  }
  case GET_MEMBER: {
    return {
      ...state,
      member: action.payload,
    };
  }
  case GET_TEAM: {
    return {
      ...state,
      team: action.payload,
    };
  }
  case GET_HR_CONNECTIONS: {
    return {
      ...state,
      hrConnections: action.payload,
    };
  }
  case GET_MS_AD_MAPPING_CONF: {
    return {
      ...state,
      hrMappingConf: action.payload,
    };
  }
  case STRIPE_PRICE_CONFIG: {
    return {
      ...state,
      price_config: action.priceConfig,
      coupon_config: action.couponConfig,
    };
  }
  case SET_ENV: {
    return {
      ...state,
      isProd: action.isProd,
    };
  }
  case GET_AD_GROUPS: {
    return {
      ...state,
      adGroups: action.payload,
    };
  }
  case GET_AD_GROUP_USERS: {
    return {
      ...state,
      adGroupUsers: { ...state.adGroupUsers, ...action.payload },
    };
  }
  case UPDATE_HR_CONNECTION_SYNC_CONF: {
    return {
      ...state,
      hrConnections: state.hrConnections.map((con) => {
        if (con.integrationSyncConfig?.id === action.payload.id) {
          return { ...con, integrationSyncConfig: { ...con.integrationSyncConfig, ...action.payload } };
        }
        return con;
      }),
    };
  }
  default:
    return state;
  }
};

export default action_switch;
