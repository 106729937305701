import React, { useContext } from "react";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import MainContext from "./context/main/mainContext";

const Login = () => {
  document.title = "Popl Internal Dash - Login";

  const mainContext = useContext(MainContext);
  const { user, login } = mainContext;

  const doLogin = (credentialResponse) => {
    login(credentialResponse.credential, (err) => {
      if (err) {
        alert(err);
      }
    });
  };

  return (
    <>
      <GoogleOAuthProvider clientId="1052764710766-lm4vacco5n6j7mniv7dpsin94tc03nni.apps.googleusercontent.com">
        <GoogleLogin onSuccess={(credentialResponse) => doLogin(credentialResponse)} onError={() => { console.log("Login Failed"); }}/>
      </GoogleOAuthProvider>
    </>
  );
};

export default Login;
