/* eslint-disable max-len */
import React, { useContext } from "react";
import MainContext from "./context/main/mainContext";
import whiteLogo from "./content/images/WhiteLogo.png";
import StripeHome from "./StripeHome";
import Search from "./Search";
import ListOfApps from "./ListOfApps";
import PostDemoFlow from "./PostDemoFlow";
import CheckoutLinks from "./CheckoutLinks";
import Quotes from "./Quotes";
import HRSync from "./hrSync";

const fileSvg = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
    <path d="M320 480H64c-17.7 0-32-14.3-32-32V64c0-17.7 14.3-32 32-32H192V144c0 26.5 21.5 48 48 48H352V448c0 17.7-14.3 32-32 32zM240 160c-8.8 0-16-7.2-16-16V32.5c2.8 .7 5.4 2.1 7.4 4.2L347.3 152.6c2.1 2.1 3.5 4.6 4.2 7.4H240zM64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V163.9c0-12.7-5.1-24.9-14.1-33.9L254.1 14.1c-9-9-21.2-14.1-33.9-14.1H64zm0 80c0 8.8 7.2 16 16 16h64c8.8 0 16-7.2 16-16s-7.2-16-16-16H80c-8.8 0-16 7.2-16 16zm0 64c0 8.8 7.2 16 16 16h64c8.8 0 16-7.2 16-16s-7.2-16-16-16H80c-8.8 0-16 7.2-16 16zM224 432c0 8.8 7.2 16 16 16h64c8.8 0 16-7.2 16-16s-7.2-16-16-16H240c-8.8 0-16 7.2-16 16zm64-96H96V272H288v64zM96 240c-17.7 0-32 14.3-32 32v64c0 17.7 14.3 32 32 32H288c17.7 0 32-14.3 32-32V272c0-17.7-14.3-32-32-32H96z" />
  </svg>
);

const alertSvg = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path class="fa-primary" d="M280 152c0-13.3-10.7-24-24-24s-24 10.7-24 24V264c0 13.3 10.7 24 24 24s24-10.7 24-24V152zM256 384a32 32 0 1 0 0-64 32 32 0 1 0 0 64z" />
    <path style={{ opacity: 0.4 }} class="fa-secondary" d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-384c13.3 0 24 10.7 24 24V264c0 13.3-10.7 24-24 24s-24-10.7-24-24V152c0-13.3 10.7-24 24-24zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z" />
  </svg>
);

const Home = () => {
  document.title = "Popl Internal Dash - Home";

  const mainContext = useContext(MainContext);
  const {
    user,
    logout,
    currentTab,
    changeTab,
    listOfApps,
    postDemoFlowStoredEmail,
    postDemoFlowStoredTeamId,
    exitPostDemoFlow,
    invoiceSent,
    postDemoFlowFinished,
    stripe_subscription_extended,
    postDemoFlowUpgrade,
  } = mainContext;

  return (
    <div className="bigHomeDiv">
      <div className="nav">
        <div onClick={() => changeTab(0)} className="imageInNav">
          <img src={whiteLogo} />
          <p>Internal Management</p>
        </div>
        <div className="middleSectionNav">
          {
            postDemoFlowStoredEmail === ""
              && postDemoFlowStoredTeamId === 0
              ? (
                <>
                  <div className={currentTab === 0 ? "optionInNav optionInNavSelected" : "optionInNav"}>
                    <p onClick={() => changeTab(0)}>Home</p>
                  </div>
                  {listOfApps.map((x, i) => {
                    // Permissions check
                    if (x.permissions?.sales && !user.can_access_sales) {
                      return;
                    }
                    if (x.permissions?.admin && !user.can_access_admin) {
                      return;
                    }
                    if (x.permissions?.ops && !user.can_access_ops) {
                      return;
                    }
                    if (x.permissions?.search && !user.can_access_search) {
                      return;
                    }

                    return (
                      <div className={x.tabId === currentTab ? "optionInNav optionInNavSelected" : "optionInNav"} key={i}>
                        <p onClick={() => changeTab(x.tabId)}>{x.tabName}</p>
                      </div>
                    );
                  })}
                </>
              ) : null
          }
        </div>
        <div className="logoutInNav">
          <p onClick={logout}>Logout</p>
        </div>
      </div>
      {
        postDemoFlowStoredEmail !== "" && postDemoFlowStoredTeamId !== 0
          ? (
            <div style={invoiceSent || postDemoFlowFinished ? { backgroundColor: "#74dc7c" } : null} className="postDemoNoticeContainer">
              <div className="textSectionAlert">
                {invoiceSent || postDemoFlowFinished ? fileSvg : alertSvg}
                {postDemoFlowFinished ? (
                  <p>
                    Team & stripe subscription made for <span>{postDemoFlowStoredEmail}</span> ({invoiceSent ? "Invoice sent" : "Invoice not sent"})
                  </p>
                ) : (
                  <p>
                    You are currently in
                    <span>{postDemoFlowUpgrade ? "Change Team Plan" : "Create New Team" }</span>
                    for
                    <span>{postDemoFlowStoredEmail}</span>
                  </p>
                )}
              </div>
              {
                !invoiceSent
                  && !postDemoFlowFinished
                  && stripe_subscription_extended === null
                  ? (
                    <div onClick={exitPostDemoFlow} className="buttonInAlert">
                      {invoiceSent || postDemoFlowFinished ? "Close" : "Exit (Without saving)"}
                    </div>
                  ) : null
              }
            </div>
          ) : null
      }
      {
        currentTab === 1 || postDemoFlowStoredEmail !== ""
          ? <StripeHome />
          : currentTab === 2
            ? <Search />
            : currentTab === 3
              ? <PostDemoFlow />
              : currentTab === 4
                ? <CheckoutLinks />
                : currentTab === 5
                  ? <Quotes />
                  : currentTab === 6
                    ? <HRSync />
                    : <ListOfApps />
      }
    </div>
  );
};

export default Home;
