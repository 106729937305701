import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import MainContext from "./context/main/mainContext";

const AdminLogin = () => {
  const mainContext = useContext(MainContext);

  const { id } = useParams();

  const {
    getLoginToken,
    member,
    isProd,
    getMemberById,
  } = mainContext;

  const adminLogin = (uid, isStaging) => {
    getLoginToken(uid, (err, token) => {
      if (err) {
        alert(err);
      }

      let baseUrl = "https://dash.popl.co/";
      if (!isProd) {
        baseUrl = "https://dev-teams.popl.co/";
      }
      if (isStaging) {
        baseUrl = "https://staging-teams.popl.co/";
      }
      // Redirect this tab
      window.location.href = `${baseUrl}sign-in?addon=${token}`;
    });
  };

  useEffect(() => {
    if (!member?.member?.uid) {
      return;
    }
    adminLogin(member?.member?.uid, false);
  }, [member]);

  useEffect(() => {
    if (!id) {
      return;
    }
    const idInt = Number.parseInt(id, 10);
    getMemberById(idInt, (err) => {
      if (err) {
        alert(err);
      }
    });
  }, [id]);

  return (
    <div className="projectContainer">
      <p>Loading...</p>
    </div>
  );
};

export default AdminLogin;
