export const icons = {
  3: {
    icon: "/assets/img/links/instagram.svg",
    text: "Instagram",
    placeholder: "Instagram username",
    path: "https://instagram.com/",
    placeholderTitle: "Instagram",
  },
  4: {
    icon: "/assets/img/links/snapchat.svg",
    text: "Snapchat",
    placeholder: "Snapchat username",
    path: "https://www.snapchat.com/add/",
    placeholderTitle: "Snapchat",
  },
  5: {
    icon: "/assets/img/links/twitter.svg",
    text: "X (Twitter)",
    placeholder: "Twitter username",
    path: "https://twitter.com/",
    placeholderTitle: "X (Twitter)",
  },
  6: {
    icon: "/assets/img/links/facebook.svg",
    text: "Facebook",
    placeholder: "Facebook profile link",
    path: "",
    placeholderTitle: "Facebook",
  },
  7: {
    icon: "/assets/img/links/linkedin.svg",
    text: "LinkedIn",
    placeholder: "LinkedIn profile link",
    path: "https://linkedin.com/in/",
    placeholderTitle: "LinkedIn",
  },
  8: {
    icon: "/assets/img/links/number.svg",
    text: "Text",
    placeholder: "Phone number",
    path: "sms:",
    placeholderTitle: "Text",
  },
  9: {
    icon: "/assets/img/links/email.svg",
    text: "Email",
    placeholder: "Email address",
    path: "mailto:",
    placeholderTitle: "Email",
  },
  10: {
    icon: "/assets/img/links/youtube.svg",
    text: "YouTube",
    placeholder: "Youtube user/channel link",
    path: "http://",
    placeholderTitle: "YouTube",
  },
  11: {
    icon: "/assets/img/links/tiktok.svg",
    text: "TikTok",
    placeholder: "TikTok username",
    path: "https://www.tiktok.com/@",
    placeholderTitle: "TikTok",
  },
  12: {
    icon: "/assets/img/links/soundcloud.svg",
    text: "SoundCloud",
    placeholder: "SoundCloud username",
    path: "https://soundcloud.com/",
    placeholderTitle: "SoundCloud",
  },
  13: {
    icon: "/assets/img/links/spotify.svg",
    text: "Spotify",
    placeholder: "Spotify link",
    path: "http://",
    placeholderTitle: "Spotify",
  },
  14: {
    icon: "/assets/img/links/apple.svg",
    text: "Apple Music",
    placeholder: "Link to Apple Music",
    path: "http://",
    placeholderTitle: "Apple Music",
  },
  15: {
    icon: "/assets/img/links/venmo.svg",
    text: "Venmo",
    placeholder: "Venmo username",
    path: "https://venmo.com/",
    placeholderTitle: "Venmo",
  },
  16: {
    icon: "/assets/img/links/cashapp.svg",
    text: "Cash App",
    placeholder: "Cash App username",
    path: "https://cash.app/$",
    placeholderTitle: "Cash App",
  },
  17: {
    icon: "/assets/img/links/paypal.svg",
    text: "PayPal",
    placeholder: "paypal.me link",
    path: "https://www.paypal.me/",
    placeholderTitle: "PayPal",
  },
  18: {
    icon: "/assets/img/links/whatsapp.svg",
    text: "WhatsApp",
    placeholder: "WhatsApp number",
    path: "https://api.whatsapp.com/send?phone=",
    placeholderTitle: "WhatsApp",
  },
  19: {
    icon: "/assets/img/links/pinterest.svg",
    text: "Pinterest",
    placeholder: "Pinterest username",
    path: "https://pinterest.com/",
    placeholderTitle: "Pinterest",
  },
  21: {
    icon: "/assets/img/links/twitch.svg",
    text: "Twitch",
    placeholder: "Twitch username",
    path: "https://www.twitch.tv/",
    placeholderTitle: "Twitch",
  },
  22: {
    icon: "/assets/img/links/contactcard.svg",
    text: "Contact Card",
    placeholder: "Phone number",
    path: "addtocontacts/",
    placeholderTitle: "Contact Card",
  },
  23: {
    icon: "/assets/img/links/customlink.svg",
    text: "Custom link",
    placeholder: "Any app or website link",
    path: "http://",
    pro: true,
    placeholderTitle: "Custom link",
  },
  24: {
    icon: "/assets/img/links/safari.svg",
    text: "Website",
    placeholder: "Website link",
    path: "http://",
    placeholderTitle: "Website",
  },
  25: {
    icon: "/assets/img/links/address.svg",
    text: "Address",
    placeholder: "Business address",
    path: "https://www.google.com/maps/search/?api=1&query=",
    placeholderTitle: "Address",
  },
  27: {
    icon: "/assets/img/links/facetime.svg",
    text: "FaceTime",
    placeholder: "FaceTime number",
    path: "facetime",
    pro: true,
    placeholderTitle: "FaceTime",
  },
  28: {
    icon: "/assets/img/links/calendly.svg",
    text: "Calendly",
    placeholder: "Calendly link",
    path: "http://",
    pro: true,
    placeholderTitle: "Calendly",
  },
  29: {
    icon: "/assets/img/links/onlyfans.svg",
    text: "OnlyFans",
    placeholder: "OnlyFans link",
    path: "http://",
    pro: true,
    placeholderTitle: "OnlyFans",
  },
  30: {
    icon: "/assets/img/links/podcasts.svg",
    text: "Podcasts",
    placeholder: "Apple podcast link",
    path: "",
    pro: true,
    placeholderTitle: "Podcasts",
  },
  31: {
    icon: "/assets/img/links/call.svg",
    text: "Call",
    placeholder: "Phone number",
    path: "tel:",
    placeholderTitle: "Call",
  },
  33: {
    icon: "/assets/img/links/discord.svg",
    text: "Discord",
    placeholder: "Discord server link",
    path: "http://",
    placeholderTitle: "Discord",
  },
  34: {
    icon: "/assets/img/links/yelp.svg",
    text: "Yelp",
    placeholder: "Yelp link",
    path: "http://",
    pro: true,
    placeholderTitle: "Yelp",
  },
  35: {
    icon: "/assets/img/links/ambassador.svg",
    text: "Popl Ambassador",
    placeholder: "Ambassador referral link",
    path: "http://",
    placeholderTitle: "Popl Ambassador",
  },
  36: {
    icon: "/assets/img/links/linktree.svg",
    text: "Linktree",
    placeholder: "Linktree link",
    path: "http://",
    placeholderTitle: "Linktree",
  },
  37: {
    icon: "/assets/img/links/file.svg",
    text: "File",
    placeholder: "Our Portfolio",
    path: "",
    pro: true,
    placeholderTitle: "File",
  },
  38: {
    icon: "/assets/img/links/clubhouse.svg",
    text: "Clubhouse",
    placeholder: "Clubhouse link",
    path: "http://",
    pro: true,
    placeholderTitle: "Clubhouse",
  },
  39: {
    icon: "/assets/img/links/embeddedvideo.svg",
    text: "Embedded Video",
    placeholder: "Your video link",
    path: "",
    pro: true,
    placeholderTitle: "Embedded Video",
  },
  41: {
    icon: "/assets/img/links/poplpay.svg",
    text: "Popl Pay",
    placeholder: "Payment name",
    path: "http://",
    placeholderTitle: "Popl Pay",
  },
  42: {
    icon: "/assets/img/links/poplpay.svg",
    text: "Connect",
    placeholder: "",
    path: "http://",
    placeholderTitle: "Connect",
  },
  43: {
    icon: "/assets/img/links/poplpay.svg",
    text: "Add Contact",
    placeholder: "",
    path: "http://",
    placeholderTitle: "Add Contact",
  },
  44: {
    icon: "/assets/img/links/hoobe.svg",
    text: "hoo.be",
    placeholder: "hoo.be Link",
    path: "",
    placeholderTitle: "hoo.be",
  },
  45: {
    icon: "/assets/img/links/square.svg",
    text: "Square",
    placeholder: "Square Appointments Link",
    path: "http://",
    pro: true,
    placeholderTitle: "Square",
  },
  46: {
    icon: "/assets/img/links/etsy.svg",
    text: "Etsy",
    placeholder: "Your Etsy link",
    path: "http://",
    pro: true,
    placeholderTitle: "Etsy",
  },
  47: {
    icon: "/assets/img/links/reviews.svg",
    text: "Reviews",
    placeholder: "Google reviews link",
    path: "http://",
    pro: true,
    placeholderTitle: "Reviews",
  },
  48: {
    icon: "/assets/img/links/mediakits.svg",
    text: "MediaKits",
    placeholder: "MediaKits link",
    path: "",
    placeholderTitle: "MediaKits",
  },
  49: {
    icon: "/assets/img/links/opensea_color.svg",
    text: "OpenSea",
    placeholder: "OpenSea link",
    path: "http://",
    pro: true,
    placeholderTitle: "OpenSea",
  },
  50: {
    icon: "/assets/img/links/app_link.svg",
    text: "App Link",
    placeholder: "Any app link",
    path: "http://",
    pro: true,
    placeholderTitle: "App Link",
  },
  51: {
    icon: "/assets/img/links/booksy.svg",
    text: "Booksy",
    placeholder: "Your Booksy link",
    link: "",
    placeholderTitle: "Booksy",
  },
  52: {
    icon: "/assets/img/links/zelle.svg",
    text: "Zelle",
    placeholder: "Zelle phone number",
    link: "zelle",
    id: 52,
    name: "zelle",
    placeholderTitle: "Zelle",
    pro: true,
  },
  53: {
    icon: "/assets/img/links/wechat.svg",
    text: "WeChat",
    placeholder: "WeChat number",
    link: "wechat",
    id: 53,
    name: "wechat",
    placeholderTitle: "WeChat",
  },
  54: {
    icon: "/assets/img/links/dropDown.svg",
    text: "Dropdown",
    placeholder: "Link Body Text",
    link: "dropdownText",
    id: 54,
    name: "dropdownText",
    placeholderTitle: "Dropdown",
  },
  55: {
    icon: "/assets/img/links/textSection.svg",
    text: "Text Section",
    placeholder: "Talk about yourself, your business and more",
    link: "textSection",
    id: 55,
    name: "textSection",
    placeholderTitle: "Text Section",
  },
  57: {
    icon: "/assets/img/links/directory.svg",
    text: "Team Directory",
    placeholder: "Team Directory",
    link: "",
    id: 57,
    name: "directory",
    placeholderTitle: "Team Directory",
  },
  58: {
    icon: "/assets/img/links/telegram.svg",
    text: "Telegram",
    placeholder: "Your Telegram link",
    link: "",
    id: 58,
    name: "telegram",
    placeholderTitle: "Telegram",
  },
  59: {
    icon: "/assets/img/links/featured.svg",
    text: "Featured",
    placeholder: "Featured Link",
    link: "",
    id: 59,
    name: "featured",
    placeholderTitle: "Featured",
  },
  63: {
    icon: "/assets/img/links/zillow.svg",
    text: "Zillow",
    placeholder: "Your Zillow Link",
    link: "",
    id: 63,
    name: "zillow",
    placeholderTitle: "Zillow",
  },
  65: {
    icon: "/assets/img/links/poshmark.svg",
    text: "Poshmark",
    placeholder: "Poshmark Link",
    link: "",
    id: 65,
    name: "poshmark",
    placeholderTitle: "Poshmark",
  },
  66: {
    icon: "/assets/img/links/chili-piper.svg",
    text: "Chili Piper",
    placeholder: "Your Chili Piper booking link",
    link: "",
    id: 66,
    pro: true,
    name: "chili-piper",
    placeholderTitle: "Chili Piper",
  },
  69: {
    icon: "/assets/img/links/threads.svg",
    text: "Threads",
    placeholder: "Threads username",
    link: "https://threads.net/@",
    id: 66,
    name: "threads",
    placeholderTitle: "Threads",
  },
  70: {
    placeholder: "Microsoft Bookings link",
    link: "",
    id: 70,
    pro: true,
    name: "microsoft-bookings",
    icon: "/assets/img/links/microsoft-bookings.svg",
    text: "Microsoft Bookings",
    placeholderTitle: "Microsoft Bookings",
  },
};

const integrationsNames = {
  microsoftTeams: "microsoftTeams",
  bambooHr: "bamboohr",
  googleWorkspace: "google-workspace",
  workday: "workday",
};

export const mergeIntegrations = [integrationsNames.bambooHr, integrationsNames.googleWorkspace, integrationsNames.workday];

export const integrationsTitles = {
  [integrationsNames.microsoftTeams]: "Azure Active Directory",
  [integrationsNames.bambooHr]: "BambooHR",
  [integrationsNames.googleWorkspace]: "Google Workspace",
  [integrationsNames.workday]: "Workday",
};

export const defaultADFieldsMapping = {
  linkData: {
    Name: ["{{displayName}}"],
    "Job Title": ["{{jobTitle}}"],
    Company: ["{{companyName}}"],
    Location: ["{{streetAddress}}, {{city}}, {{state}} {{postalCode}}", "{{officeLocation}}"],
  },
  links: [
    {
      id: 9,
      title: "Email",
      icon: "",
      updateExisting: true,
      value: ["{{mail}}", "{{userPrincipalName}}"],
      desc: [""],
    },
    {
      id: 31,
      title: "Call",
      icon: "",
      updateExisting: true,
      value: ["{{mobilePhone}}", "{{businessPhones[0]}}"],
      desc: [""],
    },
  ],
};

export const defaultMergeFieldsMapping = {
  linkData: {
    Name: ["{{display_full_name}}"],
    "Job Title": ["{{company}}"],
    Company: ["{{company}}"],
    Location: ["{{home_location}}", "{{work_location}}"],
  },
  links: [
    {
      id: 9,
      title: "Email",
      icon: "",
      updateExisting: true,
      value: ["{{personal_email}}", "{{work_email}}"],
      desc: [""],
    },
    {
      id: 31,
      title: "Call",
      icon: "",
      updateExisting: true,
      value: ["{{mobile_phone_number}}"],
      desc: [""],
    },
  ],
};
