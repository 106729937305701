/* eslint-disable max-len */
/* eslint-disable import/no-extraneous-dependencies */
import {
  CircularProgress, TextField, Tooltip, makeStyles, Popover,
} from "@material-ui/core";
/* eslint-disable no-extend-native */
import { customAlphabet } from "nanoid";
import { lowercase as lowercaseDic } from "nanoid-dictionary";
import {
  Fragment, useContext, useEffect, useState,
} from "react";
import mainContext from "../../context/main/mainContext";
import {
  defaultADFieldsMapping, defaultMergeFieldsMapping, icons, mergeIntegrations,
} from "../config";
import TestMappingModal from "./TestMappingModal";
import AddLinkModal from "./AddLinkModal";

const useStyles = makeStyles(() => ({
  headerWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "20px 50px 0 0",
  },
  testWrapper: {
    display: "flex",
    alignItems: "center",
    position: "relative",
  },
  dontHaveMapping: {
    display: "flex",
    alignItems: "center",
  },
  profileData: {
    margin: "10px 0 0 20px",
    display: "flex",
    flexDirection: "column",
  },
  mappingConf: {
    backgroundColor: "#ffffff",
    padding: 20,
    marginRight: 50,
    borderRadius: 5,
  },
  itemBox: {
    display: "flex",
    width: "100%",
  },
  label: {
    width: 100,
    margin: "5px 0 10px 0",
    wordBreak: "break-all",
  },
  inputsContainer: {
    display: "flex",
    flexDirection: "column",
    width: "calc(100% - 150px)",
  },
  inputWrapper: {
    position: "relative",
    width: "100%",
  },
  closeIcon: {
    position: "absolute",
    right: -25,
    top: 7,
    cursor: "pointer",
  },
  input: {
    color: "#690",
  },
  linksContainer: {
    margin: "10px 0 0 20px",
  },
  fieldsCont: {
    display: "flex",
    flexDirection: "column",
    padding: 20,
    maxHeight: 200,
    overflow: "auto",
    "& > span": {
      marginBottom: 5,
    },
  },
  plusIcon: {
    cursor: "pointer",
    position: "absolute",
    top: 3,
  },
  linksHeaderWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    position: "relative",

    "& > div": {
      position: "absolute",
      right: 51,
      top: 50,
    },
  },
  removeLink: {
    color: "#1f75cb",
    textDecoration: "underline",
    marginLeft: "auto",
    fontSize: 13,
    cursor: "pointer",
  },
}));

const closeIcon = <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M1 1L8 8M15 15L8 8M8 8L15 1M8 8L1 15" stroke="#828282" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>;

function Customizations({ selectedConnection }) {
  const { integrationSyncConfig } = selectedConnection;
  const classes = useStyles();
  const { updateSyncConfig, hrMappingConf } = useContext(mainContext);
  const [mapping, setMapping] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [testPopup, setTestPopup] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showAddLinkPopup, setShowAddLinkPopup] = useState(false);
  const [defaultMappingConf] = useState(mergeIntegrations.includes(selectedConnection.integration) ? defaultMergeFieldsMapping : defaultADFieldsMapping);

  const handleOpenFieldsList = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosenFieldsList = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleChangeMappingField = ({ target: { value } }, section, key, valueIndex, linkIndex) => {
    setMapping((prev) => ({
      ...prev,
      [section]: section === "links"
        ? prev[section].map((item, i) => {
          if (i === linkIndex) {
            if (["value", "desc"].includes(key)) {
              return {
                ...item,
                [key]: item[key].map((item, i) => {
                  if (i === valueIndex) {
                    return value;
                  }
                  return item;
                }),
              };
            }
            return { ...item, [key]: value };
          }
          return item;
        })
        : {
          ...prev[section],
          [key]: prev[section][key].map((item, i) => {
            if (i === valueIndex) {
              return value;
            }
            return item;
          }),
        },
    }));
  };

  const handleAddFallbackField = (section, key, linkIndex) => {
    setMapping((prev) => ({
      ...prev,
      [section]: section === "links"
        ? prev[section].map((item, i) => {
          if (i === linkIndex && ["value", "desc"].includes(key)) {
            return {
              ...item,
              [key]: [...item[key], ""],
            };
          }
          return item;
        })
        : {
          ...prev[section],
          [key]: [...prev[section][key], ""],
        },
    }));
  };

  const handleRemoveFallbackField = (section, key, valueIndex, linkIndex) => {
    setMapping((prev) => ({
      ...prev,
      [section]: section === "links"
        ? prev[section].map((item, i) => {
          if (i === linkIndex && ["value", "desc"].includes(key)) {
            if (["value", "desc"].includes(key)) {
              return {
                ...item,
                [key]: item[key].filter((item, i) => i !== valueIndex),
              };
            }
          }
          return item;
        })
        : {
          ...prev[section],
          [key]: prev[section][key].filter((item, i) => i !== valueIndex),
        },
    }));
  };

  const setUpdateUidForDefaultMapping = (mapping) => ({
    ...defaultADFieldsMapping,
    links: defaultADFieldsMapping.links.map((link) => {
      const linkFromMapping = mapping.links.find(({ id, title }) => id == link.id && title === link.title);
      return { ...link, ...(linkFromMapping?.updateUid && { updateUid: linkFromMapping.updateUid }) };
    }),
  });

  const checkEditsMade = (current, newVal) => {
    if (!mapping) return false;
    const sortedKeys = (obj) => Object.keys(obj).sort();
    const handleValue = (conf) => ({
      links: conf.links.sort((a, b) => a.id - b.id).map((link) => sortedKeys(link).reduce((acc, key) => {
        acc[key] = link[key];
        return acc;
      }, {})),
      linkData: sortedKeys(conf.linkData).reduce((acc, key) => {
        acc[key] = conf.linkData[key];
        return acc;
      }, {}),
      ...conf.member && {
        member: sortedKeys(conf.member).reduce((acc, key) => {
          acc[key] = conf.member[key];
          return acc;
        }, {}),
      },
    });
    return JSON.stringify(handleValue(current)) !== JSON.stringify(handleValue(newVal));
  };

  const handleSaveMapping = async () => {
    try {
      setIsFetching(true);
      await updateSyncConfig({ id: integrationSyncConfig.id, mappingScheme: { ...mapping, links: mapping.links.map((link) => ({ ...link, id: Number(link.id) })) } });
      setIsFetching(false);
    } catch (error) {
      console.log(error);
      setIsFetching(false);
    }
  };

  const handleResetMapping = async () => {
    try {
      if (isFetching) return;
      // setIsFetching(true);
      const resetedMapping = defaultMappingConf;
      resetedMapping.links = resetedMapping.links.map((link) => {
        // when reseting config, trying to find links with the same title and id and using old updateUid to not create duplicates
        const oldLink = mapping.links.find(({ title, id }) => title === link.title && id === link.id);
        if (oldLink) {
          return { ...link, updateUid: oldLink.updateUid || customAlphabet(lowercaseDic, 10)() };
        }
        // otherwise creating new updateUid
        return { ...link, updateUid: customAlphabet(lowercaseDic, 10)() };
      });
      await updateSyncConfig({ id: integrationSyncConfig.id, mappingScheme: resetedMapping });
      setMapping(resetedMapping);
      setIsFetching(false);
    } catch (error) {
      console.log(error);
      setIsFetching(false);
    }
  };

  const handleRemoveLink = (index) => {
    setMapping((prev) => ({
      ...prev,
      links: prev.links.filter((_, i) => i !== index),
    }));
  };

  const handleAddNewLink = (link) => {
    setMapping((prev) => ({
      ...prev,
      links: [...prev.links, link],
    }));
    setShowAddLinkPopup(false);
  };

  useEffect(() => {
    if (integrationSyncConfig.mappingScheme) setMapping(integrationSyncConfig.mappingScheme);
  }, [integrationSyncConfig?.mappingScheme]);

  return (
    <div className={classes.root}>
      <div className={classes.headerWrapper}>
        <h3>Custom mapping</h3>
        {checkEditsMade(integrationSyncConfig.mappingScheme, mapping) && <div style={{ display: "flex", marginLeft: "auto" }}>
          <div
            className="buttonContainerMini"
            onClick={() => {
              setMapping(integrationSyncConfig.mappingScheme);
            }}
            style={{
              width: 100,
              backgroundColor: "#ffffff",
              color: "#000000",
              border: "1px solid #000000",
            }}
          >
            Cancel
          </div>
          <div
            className="buttonContainerMini"
            onClick={() => {
              if (isFetching) return;
              handleSaveMapping();
            }}
            style={{ width: 100, marginLeft: 20 }}
          >
            {isFetching ? <CircularProgress color="secondary" size={15} /> : "Save"}
          </div>
        </div>}
        {!checkEditsMade(integrationSyncConfig.mappingScheme, mapping) && checkEditsMade(integrationSyncConfig.mappingScheme, setUpdateUidForDefaultMapping(integrationSyncConfig.mappingScheme)) && (
          <div
            className="buttonContainerMini"
            onClick={handleResetMapping}
            style={{
              width: 100,
              backgroundColor: "#ffffff",
              color: "#000000",
              border: "1px solid #000000",
              marginLeft: 20,
            }}
          >
            {isFetching ? <CircularProgress color="secondary" size={15} /> : "Reset to default"}
          </div>
        )}
      </div>
      {mapping && (
        <div className={classes.mappingConf}>
          <div className={classes.testWrapper}>
            <div
              className="buttonContainerMini"
              onClick={handleOpenFieldsList}
              style={{
                width: 70, position: "absolute", right: 0, top: 50,
              }}
            >
              Fields
            </div>
            <div
              className="buttonContainerMini"
              onClick={() => setTestPopup(true)}
              style={{ width: 70, marginRight: 30 }}
            >
              Test
            </div>
            <p>(This would not affect team and only show how mapping will looking for certain team's member)</p>
          </div>
          <h4>Profile data:</h4>
          <div className={classes.profileData}>
            {Object.entries(mapping.linkData).map(([key, value]) => (
              <div key={key} className={classes.itemBox}>
                <Tooltip
                  placement="top"
                  title={hrMappingConf.customResultParser[key] || ""}
                >
                  <p className={classes.label}>
                    {key}
                    {hrMappingConf.customResultParser[key] && <span>*</span>}
                :</p>
                </Tooltip>
                <div className={classes.inputsContainer}>
                  {value.map((val, i, arr) => (
                    <div key={`${key}-${i}`} className={classes.inputWrapper}>
                      <TextField
                        key={i}
                        required
                        variant="standard"
                        style={{
                          backgroundColor: "#ecedf5",
                          padding: "0 15px",
                          marginBottom: 5,
                          width: "calc(100% - 30px)",
                        }}
                        value={val}
                        onChange={(e) => handleChangeMappingField(e, "linkData", key, i)}
                        InputProps={{ disableUnderline: true, className: classes.input }}
                      />
                      {i > 0 && <div className={classes.closeIcon} onClick={() => handleRemoveFallbackField("linkData", key, i)}>{closeIcon}</div>}
                      {i === arr.length - 1 && (
                        <div
                          onClick={() => {
                            handleAddFallbackField("linkData", key);
                          }}
                          style={{ right: arr.length > 1 ? -60 : -30 }}
                          className={classes.plusIcon}
                        >
                          <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ minWidth: 25, minHeight: 25 }} ><rect y="0.5" width="24" height="24" rx="12" fill="#F2F2F2"></rect><path fillRule="evenodd" clipRule="evenodd" d="M12 7.5C11.5398 7.5 11.1667 7.8731 11.1667 8.33333V11.6667H7.83333C7.3731 11.6667 7 12.0398 7 12.5C7 12.9602 7.3731 13.3333 7.83333 13.3333H11.1667V16.6667C11.1667 17.1269 11.5398 17.5 12 17.5C12.4602 17.5 12.8333 17.1269 12.8333 16.6667V13.3333H16.1667C16.6269 13.3333 17 12.9602 17 12.5C17 12.0398 16.6269 11.6667 16.1667 11.6667H12.8333V8.33333C12.8333 7.8731 12.4602 7.5 12 7.5Z" fill="#828282"></path></svg>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
          <div className={classes.linksHeaderWrapper}>
            <h4>Links:</h4>
            <div
              className="buttonContainerMini"
              onClick={() => setShowAddLinkPopup(true)}
            >
              + Add Link
            </div>
          </div>
          {mapping.links.map((item, linkIndex) => (
            <div key={`${item.title}-${linkIndex}`} className={classes.linksContainer}>
              <div style={{
                marginBottom: 5, width: 150, fontWeight: 600, display: "flex",
              }}>
                {icons[item.id]?.text}
                <span onClick={() => handleRemoveLink(linkIndex)} className={classes.removeLink}>Remove</span>
              </div>
              {Object.entries(item).map(([key, value]) => {
                if (["title", "value", "desc"].includes(key)) {
                  return (
                    <div key={key} className={classes.itemBox}>
                      <Tooltip
                        placement="top"
                        title={hrMappingConf.customResultParser[key] || ""}
                      >
                        <p className={classes.label}>
                          {key}
                          {hrMappingConf.customResultParser[key] && <span>*</span>}
                        :</p>
                      </Tooltip>
                      <div className={classes.inputsContainer}>
                        {["value", "desc"].includes(key) // only these 2 fields have mapping ability
                          ? <>
                            {value.map((val, i, arr) => (
                              <div className={classes.inputWrapper}>
                                <TextField
                                  key={i}
                                  required
                                  variant="standard"
                                  style={{
                                    backgroundColor: "#ecedf5",
                                    padding: "0 15px",
                                    marginBottom: 5,
                                    width: "calc(100% - 30px)",
                                  }}
                                  value={val}
                                  onChange={(e) => handleChangeMappingField(e, "links", key, i, linkIndex)}
                                  InputProps={{ disableUnderline: true, className: classes.input }}
                                />
                                {i > 0 && <div className={classes.closeIcon} onClick={() => handleRemoveFallbackField("links", key, i, linkIndex)}>{closeIcon}</div>}
                                {i === arr.length - 1 && (
                                  <div
                                    onClick={() => {
                                      handleAddFallbackField("links", key, linkIndex);
                                    }}
                                    style={{ right: arr.length > 1 ? -60 : -30 }}
                                    className={classes.plusIcon}
                                  >
                                    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ minWidth: 25, minHeight: 25 }} ><rect y="0.5" width="24" height="24" rx="12" fill="#F2F2F2"></rect><path fillRule="evenodd" clipRule="evenodd" d="M12 7.5C11.5398 7.5 11.1667 7.8731 11.1667 8.33333V11.6667H7.83333C7.3731 11.6667 7 12.0398 7 12.5C7 12.9602 7.3731 13.3333 7.83333 13.3333H11.1667V16.6667C11.1667 17.1269 11.5398 17.5 12 17.5C12.4602 17.5 12.8333 17.1269 12.8333 16.6667V13.3333H16.1667C16.6269 13.3333 17 12.9602 17 12.5C17 12.0398 16.6269 11.6667 16.1667 11.6667H12.8333V8.33333C12.8333 7.8731 12.4602 7.5 12 7.5Z" fill="#828282"></path></svg>
                                  </div>
                                )}
                              </div>
                            ))}
                          </>
                          : <TextField
                            key={`${item.id}-${linkIndex}`}
                            required
                            variant="standard"
                            style={{
                              backgroundColor: "#ecedf5",
                              padding: "0 15px",
                              marginBottom: 5,
                              width: "calc(100% - 30px)",
                            }}
                            value={value}
                            onChange={(e) => handleChangeMappingField(e, "links", key, null, linkIndex)}
                            InputProps={{ disableUnderline: true }}
                          />
                        }
                      </div>
                    </div>
                  );
                }
                return null;
              })}
            </div>
          ))}
          {mapping.member && <>
            <h4>Member data:</h4>
            <div className={classes.profileData}>
              {Object.entries(mapping.member).map(([key, value]) => (
                <div key={key} className={classes.itemBox}>
                  <Tooltip
                    placement="top"
                    title={hrMappingConf.customResultParser[key] || ""}
                  >
                    <p className={classes.label}>
                      {key}
                      {hrMappingConf.customResultParser[key] && <span>*</span>}
                    :</p>
                  </Tooltip>
                  <div className={classes.inputsContainer}>
                    {value.map((val, i) => (
                      <div className={classes.inputWrapper}>
                        <TextField
                          key={i}
                          required
                          variant="standard"
                          style={{
                            backgroundColor: "#ecedf5",
                            padding: "0 15px",
                            marginBottom: 5,
                            width: "calc(100% - 30px)",
                          }}
                          value={val}
                          onChange={(e) => handleChangeMappingField(e, "linkData", key, i)}
                          InputProps={{ disableUnderline: true, className: classes.input }}
                        />
                        {i > 0 && <div className={classes.closeIcon} onClick={() => handleRemoveFallbackField("linkData", key, i)}>{closeIcon}</div>}
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </>}
        </div>
      )}
      <TestMappingModal connection={selectedConnection} open={testPopup} onClose={() => setTestPopup(false)} mappingScheme={mapping} />
      <AddLinkModal open={showAddLinkPopup} onClose={() => setShowAddLinkPopup(false)} handleAdd={handleAddNewLink} />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClosenFieldsList}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div className={classes.fieldsCont}>
          {hrMappingConf.msMemberKeys.split(",").map((key) => {
            if (key !== "onPremisesExtensionAttributes") {
              return <span key={key}>{key}</span>;
            }
            return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15].map((i) => (
              <Fragment key={`${key}-${i}`}>
                <span>extensionAttribute{i}</span>
              </Fragment>
            ));
          })}
        </div>
      </Popover>
    </div>
  );
}

export default Customizations;
