import React, { useContext, useState } from "react";
import MainContext from "./context/main/mainContext";
import FirewallModal from "./FirewallModal";

const ListOfApps = () => {
  const mainContext = useContext(MainContext);
  const {
    user, logout, currentTab, changeTab, listOfApps,
  } = mainContext;

  const [firewallmodalOpen, setFirewallModalOpen] = useState(false);

  const onCloseFirewallModal = () => {
    setFirewallModalOpen(false);
  };

  return (
    <div className="projectContainer">
      <h2 className="h2Home">Welcome {user.email}!</h2>
      <div className="appList">
        {
          listOfApps.map((x, i) => {
            // Permissions check
            if (x.permissions?.sales && !user.can_access_sales) {
              return;
            }
            if (x.permissions?.admin && !user.can_access_admin) {
              return;
            }
            if (x.permissions?.ops && !user.can_access_ops) {
              return;
            }
            if (x.permissions?.search && !user.can_access_search) {
              return;
            }
            return (
              <div className="singleAppOption" key={i} onClick={() => changeTab(x.tabId)}>
                <div className="headerOnOption">
                  <p>{x?.title}</p>
                </div>
                <div className="descOnOption">
                  {x?.description}
                </div>
              </div>
            );
          })
        }
        { user.can_access_firewall
          && <>
            <FirewallModal open={firewallmodalOpen} closeModal={onCloseFirewallModal}></FirewallModal>
            <div className="singleAppOption" onClick={() => setFirewallModalOpen(true)}>
              <div className="headerOnOption">
                <p>Firewall</p>
              </div>
              <div className="descOnOption">
              Manage your firewall rules
              </div>
            </div>
          </>
        }
      </div>
    </div>
  );
};

export default ListOfApps;
