import {
  CircularProgress, Modal, TextField, makeStyles,
} from "@material-ui/core";
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import { axiosInstance, axiosParagonInstance } from "../../utilities/axios.config";
import mainContext from "../../context/main/mainContext";
import { icons } from "../config";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    display: "block",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    maxHeight: 500,
    overflow: "auto",
  },
  searchContainer: {
    display: "flex",
    paddingTop: 10,
    marginBottom: 20,
  },
  resultContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 20,
  },
  memberSection: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#f1f2f4",
    padding: 20,
    marginLeft: "auto",
    width: "calc(50% - 20px)",
  },
  mappedSection: {
    display: "flex",
    flexDirection: "column",
    padding: 20,
  },
  linksContainer: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: 10,
  },
  itemBox: {
    display: "flex",
    width: "100%",
  },
  label: {
    width: 100,
    margin: "5px 0 10px 0",
    fontWeight: 500,
    paddingLeft: 10,
  },
  value: {
    margin: "5px 0 10px 0",
  },
  fieldItem: {
    margin: "5px 0",
    display: "flex",
    wordBreak: "break-all",
  },
  premisesAttr: {
    display: "flex",
    flexDirection: "column",
    padding: "10px 0 0 10px",
  },
}));

function TestMappingModal({
  open, onClose, mappingScheme, connection,
}) {
  const classes = useStyles();
  const { hrMappingConf } = useContext(mainContext);
  const [isSearching, setIsSearching] = useState(false);
  const [searchErrorText, setSearchErrorText] = useState("");
  const [searchText, setSearchText] = useState("");
  const [member, setMember] = useState(null);
  const [mappedFields, setMappedFields] = useState(null);

  const handleSearch = async () => {
    try {
      setIsSearching(true);
      const { data } = await axiosInstance.post("/internal/hr-sync/get_token", { uid: connection?.uid });
      const user = await axiosParagonInstance.get(`/users/${searchText}?$select=${hrMappingConf.msMemberKeys}`, {
        headers: {
          Authorization: `Bearer ${data.token}`,
          ConsistencyLevel: "eventual",
        },
      });
      const token = await axiosInstance.post("/internal/hr-sync/get-admin-token", { teamId: connection.parentTeamId });
      const mapping = await axios("/connections/hrSync/map-fields", {
        baseURL: process.env.REACT_APP_POPLBE_URL,
        method: "POST",
        headers: {
          Authorization: token.data.token,
        },
        data: {
          teamId: connection.parentTeamId,
          user: user.data,
          mappingScheme,
        },
      });
      setMember(user.data);
      setMappedFields(mapping.data.data);
      setIsSearching(false);
    } catch (error) {
      console.log(error);
      setIsSearching(false);
      setSearchErrorText(error?.response?.data?.error?.message || "Unhadled error");
      setMember(null);
    }
  };

  useEffect(() => {
    if (!open) {
      setSearchErrorText("");
      setMember(null);
      setSearchText("");
      setMappedFields(null);
    }
  }, [open]);

  return (
    <Modal
      open={open}
      onClose={onClose}
    >
      <div className={classes.root}>
        <div className={classes.searchContainer}>
          <TextField
            style={{ width: 400 }}
            variant="outlined"
            label="User Principal Name"
            onChange={(e) => setSearchText(e.target.value?.trim())}
            disabled={isSearching}
            error={searchErrorText !== ""}
            helperText={searchErrorText}
          />
          <div style={{ width: 100, margin: "14px 0 0 30px" }} className="buttonContainerMini" onClick={handleSearch}>{isSearching ? <CircularProgress color="secondary" size={15} /> : "Map fields"}</div>
        </div>
        <hr />
        <div className={classes.resultContainer}>
          {mappedFields && (
            <div className={classes.mappedSection}>
              <h4 style={{ margin: "0 0 5px 0" }}>Profile data:</h4>
              {Object.entries(mappedFields.linkData).map(([key, value]) => {
                if (typeof value === "string") {
                  return (
                    <div style={{ paddingLeft: 10 }} className={classes.fieldItem}>
                      <div style={{ fontWeight: 500, minWidth: 100, paddingLeft: 10 }}>{key}: </div><div>{value}</div>
                    </div>
                  );
                }
              })}
              <h4 style={{ margin: "20px 0 5px 0" }}>Links:</h4>
              {mappedFields.links.map((item) => (
                <div key={`${item.title}-${item.id}`} className={classes.linksContainer}>
                  <p style={{ marginBottom: 5, fontWeight: 600 }}>{icons[item.id]?.text}</p>
                  {Object.entries(item).map(([key, value]) => {
                    if (["title", "value", "desc"].includes(key) && value) {
                      return (
                        <div key={key} className={classes.itemBox}>
                          <p className={classes.label}>{key}:</p>
                          <p className={classes.value}>{value}</p>
                        </div>
                      );
                    }
                    return null;
                  })}
                </div>
              ))}
              <h4 style={{ margin: "0 0 5px 0" }}>Member data:</h4>
              {Object.entries(mappedFields.member).map(([key, value]) => {
                if (typeof value === "string") {
                  return (
                    <div style={{ paddingLeft: 10 }} className={classes.fieldItem}>
                      <div style={{
                        fontWeight: 500, minWidth: 100, width: 100, wordBreak: "break-all", paddingLeft: 10,
                      }}>{key}: </div><div style={{ marginLeft: 5 }}>{value}</div>
                    </div>
                  );
                }
              })}
            </div>
          )}
          {member && (
            <div className={classes.memberSection}>
              {Object.entries(member).map(([key, value]) => {
                if (!hrMappingConf.msMemberKeys.includes(key)) return null;
                if (typeof value === "string") {
                  return (
                    <div className={classes.fieldItem}>
                      <span style={{ color: "#905" }}>{key}</span>: <span style={{ color: "#690", marginLeft: 5 }}>{value}</span>
                    </div>
                  );
                }
                if (key === "onPremisesExtensionAttributes") {
                  return (
                    <>
                      <div className={classes.fieldItem}>
                        <span style={{ color: "#905" }}>{key}</span>:
                      </div>
                      <div className={classes.premisesAttr}>
                        {Object.entries(member[key]).map(([key, value]) => (
                          <div className={classes.fieldItem}>
                            <span style={{ color: "#905" }}>{key}</span>: <span style={{ color: "#690", marginLeft: 5 }}>{value || "null"}</span>
                          </div>
                        ))}
                      </div>
                    </>
                  );
                }
                return (
                  <div className={classes.fieldItem}>
                    <span style={{ color: "#905" }}>{key}</span>: <span style={{ color: "#690", marginLeft: 5 }}>{value?.toString() || <span style={{ color: "#808080" }}>null</span>}</span>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
}

export default TestMappingModal;
