import {
  CircularProgress, TextField, Tabs, Tab, makeStyles,
} from "@material-ui/core";
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import mainContext from "../context/main/mainContext";
import Overview from "./components/Overview";
import ConfirmSync from "./components/ConfirmSync";
import { axiosInstance } from "../utilities/axios.config";
import Customizations from "./components/Customizations";
import { integrationsTitles, mergeIntegrations } from "./config";

const useStyles = makeStyles(() => ({
  connectionsContainer: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: 20,
  },
  connectionItem: {
    display: "flex",
    alignItems: "center",
    width: 300,
    "& > div": {
      margin: "0 0 0 auto",
    },
  },
}));

const HRsync = () => {
  const classes = useStyles();
  const context = useContext(mainContext);
  const [isLoading, setIsLoading] = useState(false);
  const [inputLookupText, setInputLookupText] = useState("");
  const [inputLookupErrorText, setInputLookupErrorText] = useState("");
  const [isFetchingGroups, setIsFetchingGroups] = useState(false);
  const [tab, setTab] = useState(0);
  const [confirmSync, setConfirmSync] = useState(false);
  const [isFetchingSync, setIsFetchingSync] = useState(false);
  const [selectedConnection, setSelectedConnection] = useState(null);

  const {
    getHRconnectionsByTeamId,
    resetState,
    hrConnections,
    getAdGroups,
  } = context;

  const doLookup = () => {
    // Clear out global state
    resetState();

    // Set loading to true
    setIsLoading(true);
    setInputLookupErrorText("");

    const teamId = parseInt(inputLookupText.trim(), 10);
    if (Number.isNaN(teamId)) {
      setInputLookupErrorText("Invalid team ID");
      setIsLoading(false);
      return;
    }

    getHRconnectionsByTeamId(teamId, (err) => {
      if (err) {
        setInputLookupErrorText(`Error searching: ${err}`);
      }
      setIsLoading(false);
    });
  };

  const handleChangeStep = (_, step) => setTab(step);

  const handleSubmit = (event) => {
    event.preventDefault();
    doLookup();
  };

  const syncTeam = async () => {
    setIsFetchingSync(true);
    try {
      const { data } = await axiosInstance.post("/internal/hr-sync/get-admin-token", { teamId: selectedConnection.parentTeamId });
      await axios("/connections/hrSync/manualSync", {
        baseURL: process.env.REACT_APP_POPLBE_URL,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: data.token,
        },
        data: {
          connectionId: selectedConnection.id,
          isSilent: true,
        },
      });
      setIsFetchingSync(false);
      setConfirmSync(false);
    } catch (error) {
      setIsFetchingSync(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (selectedConnection && !mergeIntegrations.includes(selectedConnection.integration)) {
      setIsFetchingGroups(true);
      getAdGroups(selectedConnection.integrationSyncConfig.groups, selectedConnection.uid, () => setIsFetchingGroups(false));
    } else {
      console.log("MERGE CONNECTION SELECTED");
    }
  }, [selectedConnection]);

  useEffect(() => {
    if (hrConnections && selectedConnection) {
      setSelectedConnection(hrConnections.find(({ id }) => id === selectedConnection.id));
    }
  }, [hrConnections]);

  useEffect(() => () => {
    resetState();
  }, []);

  return (
    <div className="projectContainer">
      {(() => {
        if (!hrConnections) {
          return (
            <>
              <h3>Search for Team</h3>
              <div className="inputContainer">
                <form autoComplete="off" onSubmit={handleSubmit}>
                  <TextField
                    required
                    variant="outlined"
                    label="ID"
                    onChange={(e) => setInputLookupText(e.target.value.trim())}
                    disabled={isLoading}
                    error={inputLookupErrorText !== ""}
                    helperText={inputLookupErrorText}
                  />
                </form>
                <br />
                <div className="buttonContainer" onClick={isLoading ? null : doLookup}>
                  {isLoading ? (<CircularProgress color="secondary" size={15} />) : "Search"}
                </div>
              </div>
            </>
          );
        }

        if (!selectedConnection) {
          return (
            <div>
              <h3>Team's HR connections</h3>
              <div className={classes.connectionsContainer}>
                {hrConnections.map((connection) => (
                  <div key={connection.id} className={classes.connectionItem}>
                    <h5>{integrationsTitles[connection.integration]}</h5>
                    <div className="buttonContainer" onClick={() => setSelectedConnection(connection)}>
                      View
                    </div>
                  </div>
                ))}
              </div>
            </div>
          );
        }

        return (
          <div>
            <Tabs
              value={tab}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleChangeStep}
            >
              <Tab label="Overview" />
              <Tab label="Customizations" />
            </Tabs>
            <div>
              {(() => {
                if (tab === 0) {
                  return <Overview selectedConnection={selectedConnection} isFetchingGroups={isFetchingGroups} />;
                }
                if (tab === 1) {
                  return <Customizations selectedConnection={selectedConnection} />;
                }
                return null;
              })()}
            </div>
            <div
              className="buttonContainerMini"
              onClick={() => setConfirmSync(true)}
              style={{
                width: 100,
                position: "absolute",
                top: 108,
                right: 48,
              }}
            >
              Sync team
            </div>
            <ConfirmSync
              confirmSync={confirmSync}
              setConfirmSync={setConfirmSync}
              isFetching={isFetchingSync}
              onConfirm={syncTeam}
            />
          </div>
        );
      })()}
    </div>
  );
};

export default HRsync;
