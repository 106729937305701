import { TextField } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import FormGroup from "@material-ui/core/FormGroup";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
import MainContext from "./context/main/mainContext";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

const CheckoutLinks = () => {
  const mainContext = useContext(MainContext);
  const classes = useStyles();
  const { user, coupon_config, getPriceConfig } = mainContext;

  const [memberCount, setMemberCount] = useState(10);
  const [cardCount, setCardCount] = useState(10);
  const [email, setEmail] = useState("");

  const [discountId, setDiscountId] = useState("");

  // Get price config
  useEffect(() => {
    if (!coupon_config) {
      getPriceConfig((err) => {
        if (err) {
          alert(err);
        }
      });
    }
  }, []);

  useEffect(() => {
    document.title = "Popl Internal Dash - Checkout Links";
  });

  const [links, setLinks] = useState({
    share: "",
    connect: "",
    entrust: "",
    // Legacy
    starter: "",
    business: "",
    premium: "",
  });

  // Make links
  useEffect(() => {
    const emailUrlencoded = encodeURIComponent(email);

    // No cards for starter
    let share = `${process.env.REACT_APP_POPLBE_URL}/internal/up/checkout?rep=${user.id}&plan=share&members=${memberCount}&email=${emailUrlencoded}`;
    let connect = `${process.env.REACT_APP_POPLBE_URL}/internal/up/checkout?rep=${user.id}&plan=connect&members=${memberCount}&cards=${cardCount}&email=${emailUrlencoded}`;
    let entrust = `${process.env.REACT_APP_POPLBE_URL}/internal/up/checkout?rep=${user.id}&plan=entrust&members=${memberCount}&cards=${cardCount}&email=${emailUrlencoded}`;
    // Legacy
    let starter = `${process.env.REACT_APP_POPLBE_URL}/internal/up/checkout?rep=${user.id}&plan=starter&members=${memberCount}&email=${emailUrlencoded}`;
    let business = `${process.env.REACT_APP_POPLBE_URL}/internal/up/checkout?rep=${user.id}&plan=business&members=${memberCount}&cards=${cardCount}&email=${emailUrlencoded}`;
    let premium = `${process.env.REACT_APP_POPLBE_URL}/internal/up/checkout?rep=${user.id}&plan=premium&members=${memberCount}&cards=${cardCount}&email=${emailUrlencoded}`;

    if (discountId !== "") {
      share += `&discount=${discountId}`;
      connect += `&discount=${discountId}`;
      entrust += `&discount=${discountId}`;
      starter += `&discount=${discountId}`;
      business += `&discount=${discountId}`;
      premium += `&discount=${discountId}`;
    }

    setLinks({
      share,
      connect,
      entrust,
      starter,
      business,
      premium,
    });
  }, [memberCount, cardCount, discountId, email]);

  return (
    <div className="projectContainer">
      <div className="appHeaderBio">
        <h1>Checkout Links</h1>
        <p>Use this tool to make direct checkout links.</p>
        <p><b>To go digitial only, set the number of cards to 0.</b></p>
      </div>
      <div className="textInputContainer">
        <TextField required="true" variant="outlined" value={email} type="text" label={"SF Opportunity Email"} onChange={(e) => setEmail(e.target.value)} />
      </div>
      <div className="textInputContainer">
        <TextField required="true" variant="outlined" value={memberCount} type="number" label={"Member Count"} onChange={(e) => setMemberCount(e.target.value)} /><br/><br/>
      </div>
      <div className="textInputContainer">
        <TextField required="true" variant="outlined" value={cardCount} type="number" label={"Card Count"} onChange={(e) => setCardCount(e.target.value)} />
      </div>
      <div className="textInputContainer">
        <FormGroup row>
          <FormControl className={classes.formControl}>
            <InputLabel id="demo-simple-select-label">Discount</InputLabel>
            <Select
              value={discountId}
              onChange={(e) => setDiscountId(e.target.value)}
            >
              <MenuItem value={""}>{"No discount"}</MenuItem>
              { coupon_config && coupon_config.map((config) => (
                <MenuItem value={config.id}>{config.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </FormGroup>
      </div><br/>

      <hr/>

      <div className="textInputContainer">
        <TextField
          variant="outlined"
          label={"Share"}
          value={links.share}
          style={{ width: 1000 }}
          disabled={true}
        />
      </div>
      <div className="textInputContainer">
        <TextField
          variant="outlined"
          label={"Connect"}
          value={links.connect}
          style={{ width: 1000 }}
          disabled={true}
        />
      </div>
      <div className="textInputContainer">
        <TextField
          variant="outlined"
          label={"Entrust"}
          value={links.entrust}
          style={{ width: 1000 }}
          disabled={true}
        />
      </div>

      <hr/>

      <div className="textInputContainer">
        <TextField
          variant="outlined"
          label={"Starter"}
          value={links.starter}
          style={{ width: 1000 }}
          disabled={true}
        />
      </div>
      <div className="textInputContainer">
        <TextField
          variant="outlined"
          label={"Business"}
          value={links.business}
          style={{ width: 1000 }}
          disabled={true}
        />
      </div>
      <div className="textInputContainer">
        <TextField
          variant="outlined"
          label={"Premium"}
          value={links.premium}
          style={{ width: 1000 }}
          disabled={true}
        />
      </div>
    </div>
  );
};

export default CheckoutLinks;
