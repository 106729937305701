/* eslint-disable import/no-extraneous-dependencies */
import {
  Modal, TextField, makeStyles,
} from "@material-ui/core";
import { lowercase as lowercaseDic } from "nanoid-dictionary";
import { useEffect, useState } from "react";
import { customAlphabet } from "nanoid";
import { icons } from "../config";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    width: 300,
    display: "block",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    maxHeight: 500,
    overflow: "auto",
  },
  searchContainer: {
    display: "flex",
    paddingTop: 10,
    marginBottom: 20,
  },
  resultContainer: {
    display: "flex",
    flexDirection: "column",
    marginTop: 20,
  },
  linkItem: {
    padding: 10,
    borderRadius: 10,

    "&:hover": {
      backgroundColor: "#f7f7f7",
    },

    "& > div": {
      display: "flex",
      alignItems: "center",
      cursor: "pointer",

      "& > img": {
        marginRight: 20,
        width: 40,
        height: 40,
      },
    },
  },
}));

function AddLinkModal({ open, onClose, handleAdd }) {
  const classes = useStyles();
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    if (!open) {
      setSearchText("");
    }
  }, [open]);

  useEffect(() => {

  }, [searchText]);

  if (!open) return null;

  return (
    <Modal
      open={open}
      onClose={onClose}
    >
      <div className={classes.root}>
        <div className={classes.searchContainer}>
          <TextField
            style={{ width: 400 }}
            variant="outlined"
            label="Link title"
            onChange={(e) => setSearchText(e.target.value?.trim())}
          />
        </div>
        <hr />
        <div className={classes.resultContainer}>
          {Object.entries(icons).map(([key, value]) => {
            const el = (
              <div
                onClick={() => handleAdd({
                  id: key,
                  desc: [""],
                  icon: "",
                  title: value.text,
                  value: [""],
                  updateExisting: true,
                  updateUid: customAlphabet(lowercaseDic, 10)(),
                })}
                className={classes.linkItem}
                key={key}
              >
                <div>
                  <img alt='link-icon' src={`${process.env.REACT_APP_B2B_URL}${value.icon}`} />
                  <p>{value.text}</p>
                </div>
              </div>
            );
            if (searchText) {
              if (value.text.toLowerCase().includes(searchText.toLowerCase())) {
                return el;
              }
              return null;
            }
            return el;
          })}
        </div>
      </div>
    </Modal>
  );
}

export default AddLinkModal;
